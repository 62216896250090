<template>
    <div>

        <div class="pt-12 pb-12" :style="style_modalita == 'dark' ? 'background:#ffffffcc;' : 'background:#000000d1;'">
            <!-- {{  main_article_background }} -->
            <v-row justify="center">
                <v-col cols="11" sm="11" md="10" lg="9" xl="6">
                    <v-card elevation="12">
                        <v-img height="350" v-if="default_image(articolo)" :src="default_image(articolo)"
                            :lazy-src="default_image(articolo)" class="secondary--text align-end">

                            <div v-if="err_missing_translation"
                                style="position:relative; top:-210px; width:100%; left:-16px;" class="text-right">
                                <v-chip x-small>{{ err_missing_translation }}</v-chip>
                            </div>


                            <div :style="title_background" class="pr-2 text-right">
                                <span class="body-2">
                                    <!-- {{ articolo_data(articolo) }} -->
                                </span>
                            </div>
                            <div data-aos="fade-right" :data-aos-duration="aos_title_duration">
                                <Titolo :style="negative_color_style" :titolo="locale_titolo(articolo)"
                                    :fullArticle="true" />
                            </div>
                        </v-img>

                        <div data-aos="fade-right" :data-aos-duration="aos_title_duration">
                            <Titolo :style="negative_color_style" v-if="!default_image(articolo)"
                                :titolo="locale_titolo(articolo)" :fullArticle="true" />
                        </div>

                        <v-card-text>
                            <v-row no-gutters>
                                <v-col v-if="err_missing_translation" cols="12" class="text-right">

                                </v-col>
                                <v-col cols="12" md="6">
                                    <p :style="negative_color_style" v-html="locale_body(articolo)"></p>
                                </v-col>
                                <v-col cols="12" md="6"
                                    class="d-flex flex-column justify-center align-center text-center">
                                    <!-- {{ images_articolo_no_default() }} -->
                                    <v-carousel data-aos="fade-in" data-aos-duration="6000"
                                        v-if="images_articolo_no_default(articolo) && images_articolo_no_default(articolo).length > 0"
                                        hide-delimiter-background show-arrows-on-hover cycle interval="10000">
                                        <v-carousel-item v-for="(item, i) in images_articolo_no_default(articolo)"
                                            :key="i" :src="item.url" :src-lazy="item.url"
                                            reverse-transition="fade-transition" transition="fade-transition">
                                        </v-carousel-item>
                                    </v-carousel>

                                </v-col>
                            </v-row>

                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>
        </div>
        <!-- {{ $route.params.id_articolo }} -->
        <LoadArticoli :box_title="$t('loadarticoli.title')" :skip_id="$route.params.id_articolo" />
    </div>
</template>

<script>
import LoadArticoli from '@/components/LoadArticoli.vue'
import Titolo from '@/components/articoli/Titolo.vue'
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init()

export default {
    props: {},
    components: {
        LoadArticoli,
        Titolo
    },
    data: () => ({
        articolo: null,
        aos_title_duration: 1000,
        err_missing_translation: null
    }),

    metaInfo() {
        return {
            title: this.locale_titolo(this.articolo),
        };
    },

    methods: {
        load_articolo() {
            // this.$store.dispatch("articoli/act_get_articolo", this.$route.params.id_articolo).then(articolo => {
            //     this.articolo = articolo
            // })
            // Try to obtain article from vuex store
            let articoli = this.$store.getters["articoli/get_articoli"]
            let articolo = articoli.filter(articolo => {
                if (articolo.id == this.$route.params.id_articolo) {
                    return true
                }
            })
            if (articolo[0]) {
                // If founded in store...
                this.articolo = articolo[0]
                // console.log('post from store');
            } else {
                // console.log('post from www');
                // Get again the post
                this.$store.dispatch("articoli/act_get_articolo", this.$route.params.id_articolo).then(articolo => {
                    this.articolo = articolo
                })
            }
        },


        default_image(articolo) {
            const no_img_url = 'https://firebasestorage.googleapis.com/v0/b/consorzio1980-web.appspot.com/o/no_image.jpeg?alt=media&token=11012f93-cab3-405d-a061-32fbf3a2dd91'
            // const no_img_url = null
            // console.log(articolo.images);
            let default_img_url
            if (articolo && articolo.images) {
                articolo.images.forEach(image => {
                    if (image.default) {
                        default_img_url = image.url
                    }
                });
                // const img_url = articolo.images.filter(image => {
                //     if (image.default) return true
                // })[0].url
                // console.log('default_img_url', default_img_url);
                return default_img_url
            }
            // else {
            //     return no_img_url
            // }


        },

        // default_image(articolo) {
        //     // const no_img_url = 'https://firebasestorage.googleapis.com/v0/b/consorzio1980-web.appspot.com/o/no_image.jpeg?alt=media&token=11012f93-cab3-405d-a061-32fbf3a2dd91'
        //     const no_img_url = null
        //     // console.log(articolo.images);
        //     if (articolo) {
        //         if (articolo.images) {

        //             let default_img_url
        //             articolo.images.forEach(image => {
        //                 if (image.default) {
        //                     return image.url
        //                     default_img_url = image.url
        //                 }
        //             });
        //             console.log('default_img_url', default_img_url);
        //             return default_img_url

        //             // const img_url = articolo.images.filter(image => {
        //             //     if (image.default) return true
        //             // })[0].url
        //             // // console.log('img_url', img_url);
        //             // return img_url
        //         } else {
        //             return no_img_url
        //         }
        //     }
        // },

        locale_titolo(articolo) {
            if (articolo) {
                if (articolo.title[this.current_locale] && articolo.body[this.current_locale]) {
                    this.err_missing_translation = null
                    return articolo.title[this.current_locale]
                } else {
                    this.err_missing_translation = this.$t('loadarticoli.error.missing_translation')
                    return articolo.title['it']
                }
            }
            // if (articolo && articolo.title) return articolo.title[this.current_locale]
        },
        locale_body(articolo) {
            if (articolo) {
                if (articolo.title[this.current_locale] && articolo.body[this.current_locale]) {
                    this.err_missing_translation = null
                    return articolo.body[this.current_locale]
                } else {
                    this.err_missing_translation = this.$t('loadarticoli.error.missing_translation')
                    return articolo.body['it']
                }
            }
            // if (articolo && articolo.body) return articolo.body[this.current_locale]
        },
        images_articolo(articolo) {
            if (articolo && articolo.images) return articolo.images
        },
        images_articolo_no_default(articolo) {
            let images = []
            if (articolo) {
                // console.log(articolo.images);
                if (articolo.images) {
                    articolo.images.forEach(image => {
                        // console.log(image.default);
                        if (image.default != true) {
                            // console.log(image);
                            images.push(image)
                        }
                    })
                }
            }
            // console.log(images);
            return images
        }
    },
    mounted() {
        // console.log('Mounted');
        this.load_articolo()
    },
    created() { },
    computed: {
        card_shadows() {
            if (this.style_modalita == 'dark') {
                return "background: #000000b3"
            } else {
                return "background: #ffffffb3"
            }
        }
    },
    watch: {}
}
</script>

<style>

</style>